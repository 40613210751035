/* Container styles */
.contact-container {
  max-width: 800px;
  margin: 1.5rem auto 2em auto;
  padding: 2rem;
  background-color: rgba(
    255,
    255,
    255,
    0.9
  ); /* White background with slight transparency */
  border-radius: 0.5rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* More prominent shadow */
  text-align: center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); /* Add subtle text shadow for depth */
}

/* Contact options styles */
.contact-options {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.contact-option {
  flex: 1;
  margin: 0 1rem;
  padding: 1rem;
  background-color: #f8f9fa; /* Light grey background */
  border-radius: 0.5rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* More prominent shadow */
}

.contact-option h3 {
  margin-bottom: 1rem;
  color: #333; /* Darker text color for readability */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); /* Add subtle text shadow for depth */
}

.contact-option p {
  margin-bottom: 1.5rem;
  color: #555; /* Slightly lighter text color */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); /* Add subtle text shadow for depth */
}

/* Button styles */
.btn-primary {
  background: linear-gradient(45deg, #007bff, #0056b3);
  border: none;
  color: white;
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  transition: background-color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.btn-primary:hover {
  background-color: #003d80;
  transform: scale(1.05);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.btn-secondary {
  background: linear-gradient(45deg, #6c757d, #343a40);
  border: none;
  color: white;
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  transition: background-color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.btn-secondary:hover {
  background-color: #23272b;
  transform: scale(1.05);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}
