/* Header.css */

.subtle-shadow {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

header {
  left: 0;
  right: 0;
  top: 0;
}

.navbar {
  background: linear-gradient(45deg, #007bff, #0056b3);
  color: white;
  padding: 1rem;
  transition: background 0.3s ease;
}

.navbar:hover {
  background: linear-gradient(45deg, #0056b3, #007bff);
}

.navbar-brand {
  font-size: 1.8rem;
  font-weight: bold;
  color: #ffdd57;
  transition: color 0.3s ease, transform 0.3s ease;
  position: relative;
  overflow: hidden;
}

.navbar-brand::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ffdd57;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.navbar-brand:hover::before {
  visibility: visible;
  transform: scaleX(1);
}

.navbar-brand:hover {
  color: #ffc107;
  transform: scale(1.1);
}

.navbar-nav .nav-link {
  font-size: 1.2rem;
  margin-right: 1.5rem;
  color: white;
  transition: color 0.3s ease, transform 0.3s ease;
}

.navbar-nav .nav-link:hover {
  color: #ffdd57;
  transform: scale(1.1);
}

.btn-primary {
  background-color: #ffdd57;
  border: none;
  color: #0056b3;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn-primary:hover {
  background-color: #ffc107;
  transform: scale(1.1);
}

.social-icons {
  display: flex;
  align-items: center;
}

.social-icon {
  transition: transform 0.3s ease;
}

.social-icon-large {
  width: 50px;
  height: 50px;
}

.social-icon-margin {
  margin-right: 20px;
}

.social-icon:hover {
  transform: scale(1.1);
}

.linkedin {
  background-color: #0a66c2;
  border-radius: 50%;
  padding: 0.1em;
  color: white; /* Ensure the icon is visible */
}

.github {
  background-color: #333;
  border-radius: 50%;
  padding: 0.1em;
  color: white; /* Ensure the icon is visible */
}

.request-contact-info-btn {
  margin-right: 20px !important;
}
