/* Ensure the app container takes the full height of the viewport */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Make the main content area grow to fill the available space */
.content {
  flex: 1;
  padding-top: 5rem;
}

.parallax {
  position: relative;
  height: auto;
  overflow: hidden;
}

.parallax::before {
  content: '';
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url('https://images-patrickengelbert.s3.us-east-2.amazonaws.com/Design.png') no-repeat center center;
  background-size: cover;
  will-change: transform; /* Optimize performance */
  z-index: -1;
}

@media (max-width: 768px) {
  .parallax::before {
    background-attachment: scroll; /* Fallback for better performance on small devices */
  }
}

.header-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  width: 80%;
}

.header-title {
  font-size: 3rem;
  font-weight: bold;
}

.header-subtitle {
  font-size: 1.5rem;
  font-weight: 300;
}

.header-lead {
  font-size: 1.25rem;
  margin-top: 1rem;
}

/* Footer styles */
footer {
  background: linear-gradient(45deg, #6c757d, #343a40);
  color: white;
  text-align: center;
  padding: 1rem 0;
  margin-top: auto;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 100;
}

.sticky-wrapper {
  position: relative;
  height: 3rem;
}

.sticky .sticky-inner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
