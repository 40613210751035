/* Header content styles */
.header-internal-content {
  text-align: center;
  margin-top: 1rem; /* Push the header down and away from the fixed header */
  color: #ffffff; /* Change the text color to white */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add text shadow for depth */
  background-color: rgba(0, 0, 0, 0.6); /* Add semi-transparent background */
  border-radius: 15px; /* Add rounded corners */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25); /* Add subtle shadow for depth */
  padding: 10px; /* Add some padding for the content against the transparent backgrouns */
  animation: popOut 0.5s ease-out;
  transition: color 0.2s ease-in-out;
}

.header-internal-content:hover {
  color: #007bff;
}

/* Pop-out animation */
@keyframes popOut {
  0% {
    transform: scale(0.9);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.header-title {
  font-size: 3rem;
  font-weight: bold;
}

.header-subtitle {
  font-size: 1.5rem;
  font-weight: 300;
}

.header-lead {
  font-size: 1.25rem;
  margin-top: 1rem;
}

/* Card background styles */
.card-background {
  background-color: rgba(255, 255, 255, 0.9); /* White background with slight transparency */
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Slight shadow */
  margin-bottom: 1.5rem;
}

/* Existing styles */
.hover {
  transition: all 0.2s ease-in-out;
}

.attention-border {
  border: 2px solid #007bff; /* Blue border color */
  border-radius: 10px; /* Rounded corners */
  padding: 15px; /* Padding inside the border */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin-top: 20px; /* Space above the border */
  margin-bottom: 20px; /* Space below the border */
}

.custom-list {
  margin-top: 5px; /* Adjust this value to increase spacing before the list */
  margin-bottom: 0px; /* Adjust this value to reduce spacing after the list */
}

.margin-right {
  margin-right: 5px;
}

.flex-container {
  display: flex;
  justify-content: space-between;
}

.flex-item {
  flex: 1;
  margin: 0 10px 20px 0px; /* Add margin between the items and below them */
}

.hover:hover {
  transform: scale(1.025);
}

.img-thumbnail {
  max-height: 100%;
}

.max-size {
  max-height: 20em;
}

.recoil-letter {
  display: inline-block;
  transition: transform 0.1s ease-out;
}
